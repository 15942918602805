<template>
    <div class="surface-0 overflow-hidden">
<ScrollTop />
        <div class="py-4 px-4 mx-0 md:mx-6 lg:mx-8 lg:px-8 flex align-items-center justify-content-between relative lg:static">
            <router-link to="/" class="flex align-items-center">
                <span class="text-900 font-medium text-2xl line-height-3 mr-8">SPBPORTAL</span>
            </router-link>
            <a class="cursor-pointer block lg:hidden text-700 p-ripple" v-ripple
                v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
                <i class="pi pi-bars text-4xl"></i>
            </a>
            <div class="surface-0 align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 px-6 lg:px-0 z-2" style="top:92%">
                <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row cursor-pointer">
                    <li>
                        <a @click="smoothScroll('#hr')" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                            <Button label="HR - ฝ่ายบุคคล" class="p-button-outlined p-button-secondary" />
                        </a>
                    </li>
                    <li>
                        <a @click="smoothScroll('#it')" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                            <Button label="IT - ฝ่าย IT" class="p-button-outlined p-button-secondary" />
                        </a>
                    </li>
                    <li>
                        <a @click="smoothScroll('#ga')" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                            <Button label="GA - ธุรการ" class="p-button-outlined p-button-secondary" />
                        </a>
                    </li>
                    <li>
                        <a @click="smoothScroll('#ptg')" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                            <Button label="พยัคฆ์ทรานส์จิสติก" class="p-button-outlined p-button-secondary" />
                        </a>
                    </li>
                    <li>
                        <a @click="smoothScroll('#pyt')" class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3 p-ripple" v-ripple>
                            <Button label="พยัคฆ์ขนส่ง" class="p-button-outlined p-button-secondary" />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="py-4 px-4 lg:px-8 mt-0 mx-0 lg:mx-8" id="hr">
            <div class="grid justify-content-center">
                <div class="col-12 text-center mt-2 mb-0">
                    <h2 class="text-900 font-normal mb-1">HR - ฝ่ายบุคคล</h2>
                </div>

                <router-link to="/hrwt" target="_blank" class="col-12 sm:col-12 p-3 md:col-6 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:5px; border-radius:10px; background: linear-gradient(90deg, rgba(145,226,237,0.5),rgba(251, 199, 145, 0.5)), linear-gradient(180deg, rgba(253, 228, 165, 0.5), rgba(172, 180, 223, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-red-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-clock text-2xl text-red-700"></i>
                            </div>
                            <h5 class="mb-1 text-900">Webtime</h5>
                            <span class="text-600">ลงเวลา และ OT</span>
                        </div>
                    </div>
                </router-link>
        
                <router-link to="/hrws" target="_blank" class="col-12 sm:col-12 p-3 md:col-6 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:5px; border-radius:10px; background: linear-gradient(90deg, rgba(145,226,237,0.5),rgba(251, 199, 145, 0.5)), linear-gradient(180deg, rgba(253, 228, 165, 0.5), rgba(172, 180, 223, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-cyan-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-money-bill text-2xl text-cyan-700"></i>
                            </div>
                            <h5 class="mb-1 text-900">Webslip</h5>
                            <span class="text-600">สลิปเงินเดือน</span>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>

        <div class="py-4 px-4 lg:px-8 mt-0 mx-0 lg:mx-8" id="it">
            <div class="grid justify-content-center">
                <div class="col-12 text-center mt-2 mb-0">
                    <h2 class="text-900 font-normal mb-1">IT - ฝ่าย IT</h2>
                </div>
                <router-link to="/itRequest" target="_blank" class="col-12 sm:col-12 p-3 md:col-6 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:5px; border-radius:10px; background: linear-gradient(90deg, rgba(145,226,237,0.5),rgba(251, 199, 145, 0.5)), linear-gradient(180deg, rgba(253, 228, 165, 0.5), rgba(172, 180, 223, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-green-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-check-square text-2xl text-green-700"></i>
                            </div>
                            <h5 class="mb-1 text-900">แจ้งงานฝ่าย IT</h5>
                            <span class="text-600">IT Service</span>
                        </div>
                    </div>
                </router-link>

                <router-link to="/wmpy" target="_blank" class="col-12 sm:col-12 p-3 md:col-6 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:5px; border-radius:10px; background: linear-gradient(90deg, rgba(145,226,237,0.5),rgba(251, 199, 145, 0.5)), linear-gradient(180deg, rgba(253, 228, 165, 0.5), rgba(172, 180, 223, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-yellow-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-envelope text-2xl text-yellow-700"></i>
                            </div>
                            <h5 class="mb-1 text-900">E-mail (@phayak.com)</h5>
                            <span class="text-600">phayak webmail (roundcube)</span>
                        </div>
                    </div>
                </router-link>
        
                <router-link to="/wmpt" target="_blank"  class="col-12 sm:col-12 p-3 md:col-6 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:5px; border-radius:10px; background: linear-gradient(90deg, rgba(145,226,237,0.5),rgba(251, 199, 145, 0.5)), linear-gradient(180deg, rgba(253, 228, 165, 0.5), rgba(172, 180, 223, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-cyan-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-envelope text-2xl text-cyan-700"></i>
                            </div>
                            <h5 class="mb-1 text-900">E-mail (@payuktrans.com)</h5>
                            <span class="text-600">payuktrans webmail (roundcube)</span>
                        </div>
                    </div>
                </router-link>
        
                <router-link to="/wmsb" target="_blank" class="col-12 sm:col-12 p-3 md:col-6 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:5px; border-radius:10px; background: linear-gradient(90deg, rgba(145,226,237,0.5),rgba(251, 199, 145, 0.5)), linear-gradient(180deg, rgba(253, 228, 165, 0.5), rgba(172, 180, 223, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-red-200" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-envelope text-2xl text-red-700"></i>
                            </div>
                            <h5 class="mb-1 text-900">E-mail (@sangpaibul.com)</h5>
                            <span class="text-600">sangpaibul webmail (MS365/outlook)</span>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>

        <div class="py-4 px-4 lg:px-8 mt-0 mx-0 lg:mx-8" id="ga">
            <div class="grid justify-content-center">
                <div class="col-12 text-center mt-2 mb-0">
                    <h2 class="text-900 font-normal mb-1">GA - ฝ่ายธุรการ</h2>
                </div>

                <router-link to="/amrq" target="_blank" class="col-12 sm:col-12 p-3 md:col-6 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:5px; border-radius:10px; background: linear-gradient(90deg, rgba(145,226,237,0.5),rgba(251, 199, 145, 0.5)), linear-gradient(180deg, rgba(253, 228, 165, 0.5), rgba(172, 180, 223, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-yellow-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-check-square text-2xl text-yellow-700"></i>
                            </div>
                            <h5 class="mb-1 text-900">แจ้งงานฝ่ายธุรการ</h5>
                            <span class="text-600">GA Service</span>
                        </div>
                    </div>
                </router-link>
        
                <router-link to="/ampl" target="_blank" class="col-12 sm:col-12 p-3 md:col-6 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:5px; border-radius:10px; background: linear-gradient(90deg, rgba(145,226,237,0.5),rgba(251, 199, 145, 0.5)), linear-gradient(180deg, rgba(253, 228, 165, 0.5), rgba(172, 180, 223, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-cyan-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-user-edit text-2xl text-cyan-700"></i>
                            </div>
                            <h5 class="mb-1 text-900">แบบประเมินการทำความสะอาด</h5>
                            <span class="text-600">MSForm</span>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="py-4 px-4 lg:px-8 mt-0 mx-0 lg:mx-8" id="ptg">
            <div class="grid justify-content-center">
                <div class="col-12 text-center mt-2 mb-0">
                    <h2 class="text-900 font-normal mb-1">PTG - พยัคฆ์ทรานส์จิสติก</h2>
                </div>

                <router-link to="/itRequest" target="_blank" class="col-12 sm:col-12 p-3 md:col-6 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:5px; border-radius:10px; background: linear-gradient(90deg, rgba(145,226,237,0.5),rgba(251, 199, 145, 0.5)), linear-gradient(180deg, rgba(253, 228, 165, 0.5), rgba(172, 180, 223, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-gray-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-check-square text-2xl text-gray-700"></i>
                            </div>
                            <h5 class="mb-1 text-900">แจ้งปัญหา</h5>
                            <span class="text-600">-</span>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        <div class="py-4 px-4 lg:px-8 mt-0 mx-0 lg:mx-8" id="pyt">
            <div class="grid justify-content-center">
                <div class="col-12 text-center mt-2 mb-0">
                    <h2 class="text-900 font-normal mb-1">PYT - พยัคฆ์ขนส่ง</h2>
                </div>

                <router-link to="/pytg" target="_blank" class="col-12 sm:col-12 p-3 md:col-6 lg:col-4 p-0 lg:pr-5 lg:pb-5 mt-4 lg:mt-0">
                    <div style="height:160px; padding:5px; border-radius:10px; background: linear-gradient(90deg, rgba(145,226,237,0.5),rgba(251, 199, 145, 0.5)), linear-gradient(180deg, rgba(253, 228, 165, 0.5), rgba(172, 180, 223, 0.2));">
                        <div class="p-3 surface-card h-full" style="border-radius:8px;">
                            <div class="flex align-items-center justify-content-center bg-gray-200 mb-3" style="width:3.5rem;height:3.5rem; border-radius:10px;">
                                <i class="pi pi-fw pi-check-square text-2xl text-gray-700"></i>
                            </div>
                            <h5 class="mb-1 text-900">แจ้งอู่ซ่อม พยัคฆ์ขนส่ง</h5>
                            <span class="text-600">GoogleForm</span>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        smoothScroll(id){
            document.querySelector(id).scrollIntoView({
                behavior: 'smooth'
            });
        }
    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return 'white';
            return 'dark';
        }
    }
}
</script>